



































































































































































































import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import MonthPicker from '@/app/ui/components/DateTimePickerV2/MonthPicker/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { EnumCourierAttendanceStatus } from '@/app/infrastructures/misc/Constants/courierAttendance'
import {
  ManageCourierAttendanceDetailSortFields,
  ManageCourierAttendanceDetailSortType,
} from '@/app/infrastructures/misc/Constants/tableFields'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import PayrollController from '@/app/ui/controllers/PayrollController'
import { CourierAttendanceDetails } from '@/domain/entities/Payroll'
import { Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import { DataObject, HeaderObject, SortValueObject } from '@/app/ui/components/DataTableV2/type'

@Component({
  components: {
    DateTimePickerV2,
    MonthPicker,
    DropdownSelect,
    DataTableV2,
    EmptyState,
    Button,
    AscendingIcon,
    LoadingOverlay,
    Badge,
  },
})
export default class CourierAttendanceDetailPage extends Vue {
  controller = PayrollController

  enumCourierAttendanceStatus = EnumCourierAttendanceStatus
  enumSortFields = ManageCourierAttendanceDetailSortFields
  enumSelectedSort = ManageCourierAttendanceDetailSortType

  selectedSort: ManageCourierAttendanceDetailSortType | null = null
  statusOptions = [
    {
      label: 'Semua',
      value: this.enumCourierAttendanceStatus.ALL,
    },
    {
      label: 'Hadir',
      value: this.enumCourierAttendanceStatus.ATTEND,
    },
    {
      label: 'Hari Dari Persetujuan',
      value: this.enumCourierAttendanceStatus.ATTEND_FROM_REQUEST,
    },
    {
      label: 'Menunggu Dari Persetujuan',
      value: this.enumCourierAttendanceStatus.ATTEND_WAITING,
    },
    {
      label: 'Absen',
      value: this.enumCourierAttendanceStatus.ABSENT,
    },
    {
      label: 'Belum Absensi',
      value: this.enumCourierAttendanceStatus.NOT_VERYFY,
    },
  ]

  parameters = {
    date: new Date(),
    status: this.statusOptions[0],
    sort: <SortValueObject> {
      name: '',
      direction: ''
    }
  }

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Tanggal absensi', '200px', true, 'date_presence', '30px'),
    this.headerCellMapper('Status', '250px'),
    this.headerCellMapper('Atur', ''),
  ]

  tableData: DataObject[][] = []

  detailData: CourierAttendanceDetails = {
    dataCourier: {
      courierId: 0,
      courierName: '-',
      courierPhone: '-',
      courier3lc: '-',
    },
    summary: {
      absent: 0,
      attendance: 0,
      notVerify: 0,
      presence: 0,
      waitingAttendance: 0,
    },
    totalDayOfMonth: 0,
    totalDayPresence: 0,
    shuttleId: '',
    shuttleName: ''
  }

  get params(): Record<string, string | number> {
    return {
      filterStatus: this.parameters.status.value,
      sortBy: <string>this.parameters.sort.name,
      direction: <string>this.parameters.sort.direction
    }
  }

  get shuttlePrefix(): string {
    return this.detailData.shuttleId ? `${this.detailData.shuttleId} - ${this.detailData.shuttleName}` : '-'
  }

  created(): void {
    if (!Utils.isSuperAdmin() && !Utils.isFinance()) {
      this.$notify({
        title: 'Forbidden Access',
        text: 'Your Account is not permitted to request to some endpoints',
        type: 'error',
        duration: 5000,
      })

      this.$router.push('/')
    }

    this.fetchList()
  }

  public fetchList(): void {
    const courierId = parseInt(this.$route.params.courierId)
    const payload = {
      params: this.params,
      date: this.parameters.date,
      courierId: courierId,
    }
    this.controller.fetchCourierAttendanceDetail(payload)
  }

  public onSort(sort: SortValueObject): void {
    this.parameters.sort = sort

    this.fetchList()
  }

  public formatStatus(
    status: EnumCourierAttendanceStatus
  ): {
    text: string
    type: string
  } {
    switch (status) {
      case EnumCourierAttendanceStatus.ABSENT:
        return {
          text: 'Absen',
          type: 'error-1',
        }

      case EnumCourierAttendanceStatus.ATTEND:
        return {
          text: 'Hadir',
          type: 'success',
        }

      case EnumCourierAttendanceStatus.ATTEND_FROM_REQUEST:
        return {
          text: 'Hadir Dari Persetujuan',
          type: 'success',
        }

      case EnumCourierAttendanceStatus.ATTEND_WAITING:
        return {
          text: 'Menunggu Persetujuan',
          type: 'warning',
        }

      case EnumCourierAttendanceStatus.NOT_VERYFY:
        return {
          text: 'Belum Absen',
          type: 'disabled',
        }

      default:
        return {
          text: '',
          type: '',
        }
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    withSort?: boolean,
    sortName?: string,
    sortGap?: string
  ): HeaderObject {
    return {
      title: <string>title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
      withSort,
      sortName,
      sortStyle: {
        marginLeft: sortGap
      }
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): DataObject {
    return {
      value: <string>value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.dataCourierAttendanceDetail')
  onDataCourierAttendanceDetailChanged(data: CourierAttendanceDetails): void {
    this.detailData = data
    this.tableData =
      data.presenceDetail?.map((presence, index) => {
        return [
          this.tableCellMapper(index + 1, '60px'),
          this.tableCellMapper(
            Utils.formatDateWithIDLocale(
              <string>presence.datePresence,
              'dddd, D MMM YYYY'
            ),
            '200px'
          ),
          this.tableCellMapper(<string>presence.status, '250px'),
          this.tableCellMapper(
            [<string>presence.datePresence, <string>presence.status],
            ''
          ),
        ]
      }) || []
  }
}
